import { Box } from '@chakra-ui/react'
import React from 'react'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  return (
    <>
      <Helmet title="sovie.or.at | Seite nicht gefunden"></Helmet>
      <Box mt="24rem" mb="16rem" maxW="75rem" ml="auto" mr="auto">
        Seite nicht gefunden.
      </Box>
    </>
  )
}

export default NotFoundPage
